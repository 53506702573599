import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { Binder } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import classNames from 'classnames';
import { useEffect } from 'react';
import { MIDDLEWARE_STRATE } from '../../helpers/oneNavigation/middleware';
import CoverImage from '../CoverImage/CoverImage';
import styles from './Banner.css';
import { BannerProps } from './types';

function Banner({ contents, from, onFocusable }: BannerProps): JSX.Element | null {
  const isFromShowcase = from === Template.Showcase;

  useEffect(() => {
    if (onFocusable && contents.length) {
      onFocusable();
    }
  }, [contents.length, onFocusable]);

  if (!contents.length) {
    return null;
  }

  return (
    <Binder middleware={MIDDLEWARE_STRATE}>
      <ul
        className={classNames(styles.banner__cover, 'banner', {
          [styles['banner__cover--showcase']]: isFromShowcase,
        })}
      >
        {contents.map((content, index: number) => (
          <li key={`banner-${content.contentID || index}`}>
            <CoverImage
              isBackgroundTransparent={isFromShowcase}
              key={content ? content.contentID : index}
              image={{ mobile: content.URLImageOptimizedCompact, default: content.URLImageOptimizedRegular }}
              onClickData={content}
              dimensions={isFromShowcase ? DIMENSIONS.SHOWCASE.banner : DIMENSIONS.BANNER}
              label={'label' in content ? content.label : undefined}
              loading="lazy"
              isArtDirection
            />
          </li>
        ))}
      </ul>
    </Binder>
  );
}

export default Banner;
